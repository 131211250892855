<template>
  <div>
    <request />
  </div>
</template>

<script>
export default {
  components: {
    request: () => import("@/components/request/request.vue")
  }
};
</script>
